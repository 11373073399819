import { createMuiTheme } from '@material-ui/core/styles'

const imp = '!important'

const customSelect = {
  fontSize: `14px ${imp}`,
  borderRadius: `6px ${imp}`,
  border: `1px solid #F2F2F2 ${imp}`,
  padding: `7px 10px ${imp}`,
  background: `#F2F2F2 ${imp}`,
  '&:focus': {
    outline: `none ${imp}`,
  },
}

const theme = createMuiTheme({
  themeFont: {
    main: 'Lato',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: "'Lato', sans-serif",
    h1: {
      fontSize: 34,
      lineHeight: 1.15,
      fontWeight: 400,
    },
    h2: {
      fontSize: 26,
      lineHeight: 1.15,
      fontWeight: 400,
    },
    h3: {
      fontSize: 20,
      lineHeight: 1.4,
      fontWeight: 400,
    },
    h4: {
      fontSize: 18,
      lineHeight: 1.55,
      fontWeight: 300,
    },
    h5: {
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 300,
    },
    h6: {
      fontSize: 14,
      lineHeight: 1.25,
      fontWeight: 300,
    },
    body1: {
      fontSize: 17,
      lineHeight: 1.67,
      fontWeight: 300,
    },
    body2: {
      fontSize: 15,
      lineHeight: 1.67,
      fontWeight: 300,
    },
    caption: {
      fontSize: 14,
      lineHeight: 1.17,
      fontWeight: 300,
    },
    button: {
      fontSize: 17,
      lineHeight: 1.67,
      fontWeight: 300,
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#b86125',
    },
    success: {
      main: '#47E169',
    },
    error: {
      main: '#FA3232',
    },
    warning: {
      main: '#D54117',
    },
    divider: '#f0f0f0',
    primaryTransparent: {
      main: 'rgb(0, 0, 0, .4)',
      light: 'rgb(0, 0, 0, .1)',
    },
    secondaryTransparent: {
      main: 'rgba(247, 239, 237, .4)',
      light: 'rgba(247, 239, 237, .1)',
    },
    ternary: {
      main: '#0082C3',
      light: '#F1FAFF',
    },
    accent: {
      main: '#b86125',
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 90,
    logoWidth: 110,
    logoHeight: 30,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: '#757575',
    greyLight: '#f2f2f2',
    greyMedium: '#333333',
    greyText: '#333333',
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.product': {
          '&__area': {
            '@media (min-width: 1024px)': {
              maxWidth: `1024px ${imp}`,
            },
          },
          '&__head': {
            flexWrap: `wrap ${imp}`,
          },
        },
        '.label': {
          fontSize: `11px ${imp}`,
        },
        '.select': {
          ...customSelect,
        },
      },
    },
    MuiButton: {
      root: {
        padding: '12px 45px',
        borderRadius: 0,
      },
      sizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
      sizeLarge: {
        padding: '12px 45px',
      },
      contained: {
        borderRadius: `0 ${imp}`,
        boxShadow: 'none',
        '&:hover, &:focus, &:active, &.selected': {
          boxShadow: 'none',
        },
      },
      outlined: {
        padding: '12px 45px',
      },
      outlinedSizeLarge: {
        padding: '12px 45px',
      },
      outlinedSizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
    },
    MuiPaper: {
      root: {
        boxShadow: `0px 0px 15px rgba(46, 46, 46, 0.1) ${imp}`,
      },
      rounded: {
        borderRadius: 0,
      },
    },
    MuiSelect: {
      root: {
        color: '#2933E7',
      },
      selectMenu: {
        '&:focus': {
          background: '#fff',
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
        fontSize: 15,
        fontWeight: 700,
        color: '#000',
        backgroundColor: 'transparent',
        height: 25,
        padding: 0,
      },
      label: {
        padding: 0,
      },
      sizeSmall: {
        height: 22,
        fontSize: 12,
        fontWeight: 400,
      },
      labelSmall: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      colorPrimary: {
        color: '#2933E7',
        backgroundColor: 'transparent',
      },
      colorSecondary: {
        color: '#E54C80',
        backgroundColor: 'transparent',
      },
    },
  },
})

export default theme
